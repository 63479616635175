import { firebaseConfig } from '../../../../src/environments/firebase.config';
import { bugsnagConfig } from '../../../../src/environments/bugsnag.config';

export const environment = {
  production: true,
  emulator: false,
  teacherURL: 'https://teachers-test-79ac4.firebaseapp.com',
  adminURL: 'https://administrators-test.firebaseapp.com',
  firebaseConfig,
  bugsnagConfig: {
    ...bugsnagConfig,
    stage: 'production',
    appType: 'Administrator',
  },
};
