export const firebaseConfig = {
  apiKey: 'AIzaSyD_wcFBBpXJzue1582jSouoRfBHJX4DHYg',
  authDomain: 'roll-scroll-dev-f5c68.firebaseapp.com',
  databaseURL: 'https://roll-scroll-dev-f5c68.firebaseio.com',
  projectId: 'roll-scroll-dev-f5c68',
  storageBucket: 'roll-scroll-dev-f5c68.appspot.com',
  messagingSenderId: '71135750779',
  appId: '1:71135750779:web:f98498f70bca5e51dfd0de',
  measurementId: 'G-P4601NL95J',
  timestampsInSnapshots: true,
};
